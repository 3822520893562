<template>
  <div class="bidding__wrapper">
    <div class="nav-box">
      <tabNav :navList="navList" v-model="type" @input="handleTypeChange" />
      <a-input-search
        style="width: 320px"
        v-model="search"
        placeholder="请输入视频名称"
        enter-button="搜索"
        @search="onSearch"
      />
    </div>
    <div class="content_list video-list" style="height: 730px; padding: 0 24px">
      <v-card-list
        ref="cardList"
        :getDataApi="getDataApi"
        :renderItem="renderItem"
        :initPagination="pagination"
        :colConfig="{
          span: 4,
        }"
      >
        <a-empty class="empty" slot="empty" />
      </v-card-list>
    </div>
  </div>
</template>

<script>
import microClassCard from '@/components/microClassCard.vue'
import tabNav from '@/views/home/components/tab-nav.vue'

import { getVideoList } from '@/api/video'
export default {
  name: 'Index',
  components: {
    microClassCard,
    tabNav
  },
  data () {
    return {
      // 搜索
      search: '',
      // 数据
      List: [],
      // 总数
      navList: [
        {
          label: '视频',
          value: '0'
        },
        {
          label: '微课',
          value: '1'
        }
      ],
      type: '0',
      pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: false,
        showTotal: (total) => `总数 ${total} 条`
      }
    }
  },
  methods: {
    microClass (val) {
      window.open(`/videoMicro/videoMicroClassInfo?id=${val.id}`)
    },
    onSearch () {
      this.$refs.cardList.pageInit()
    },
    handleTypeChange (value) {
      this.type = value
      this.$refs.cardList.pageInit()
    },
    getDataApi (params) {
      return getVideoList({
        ...params,
        name: this.search,
        videoType: this.type
      })
    },
    renderItem ({ item }) {
      return (
        <div class="list-item" onClick={this.microClass.bind(null, item)}>
          <microClassCard cardInfo={item} />
        </div>
      )
    }
  }
}
</script>
<style lang="less">
@import url('../../common/style');
</style>
<style lang="less" scoped>
.bidding__wrapper {
  .nav-box {
    height: 70px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .video-list {
    ::v-deep .ant-col  {
      width: 20% !important;
    }
  }
  .list-item {
    padding: 6px;
    cursor: pointer;
  }
}
</style>
